import { Fragment } from 'react';

import { Col, Grid } from '@playbooks/interface/grid';
import { BorderLink } from '@playbooks/interface/links';
import { FollowBtn } from 'components/follow/follow-btn';

export const ToolCardActions = ({ type, tool, rootLink }) => {
	const props = { tool, rootLink };

	// Render
	switch (type) {
		case 'grid':
			return <ToolGridCardActions {...props} />;

		case 'list':
			return <ToolListCardActions {...props} />;
	}
};

export const ToolGridCardActions = ({ tool, rootLink }) => (
	<Grid>
		<Col span='6'>
			<BorderLink size='xs' href={`${rootLink}/tools/${tool.uuid}`} width='w-full'>
				View
			</BorderLink>
		</Col>
		<Col span='6'>
			<FollowBtn type='card' model={tool} rootApi={`/tools/${tool.uuid}`} width='w-full' />
		</Col>
	</Grid>
);

export const ToolListCardActions = ({ tool, rootLink }) => (
	<Fragment>
		<FollowBtn type='card' model={tool} rootApi={`/tools/${tool.uuid}`} />
	</Fragment>
);
