import { Fragment } from 'react';

import { Col, Grid } from '@playbooks/interface/grid';
import { BorderLink } from '@playbooks/interface/links';
import { FollowBtn } from 'components/follow/follow-btn';

export const FrameworkCardActions = ({ type, framework, rootLink }) => {
	const props = { framework, rootLink };

	// Render
	switch (type) {
		case 'grid':
			return <FrameworkGridCardActions {...props} />;

		case 'list':
			return <FrameworkListCardActions {...props} />;
	}
};

export const FrameworkGridCardActions = ({ framework, rootLink }) => (
	<Grid>
		<Col span='6'>
			<BorderLink size='xs' href={`${rootLink}/frameworks/${framework.uuid}`} width='w-full'>
				View
			</BorderLink>
		</Col>
		<Col span='6'>
			<FollowBtn type='card' model={framework} rootApi={`/frameworks/${framework.uuid}`} width='w-full' />
		</Col>
	</Grid>
);

export const FrameworkListCardActions = ({ framework, rootLink }) => (
	<Fragment>
		<FollowBtn type='card' model={framework} rootApi={`/frameworks/${framework.uuid}`} />
	</Fragment>
);
