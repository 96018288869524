import { useRouter } from 'next/router';

import { BorderLink } from '@playbooks/interface/links';
import { FrameworkCardActions } from 'components/framework/framework-card-actions';
import {
	DetailCard,
	DisplayCard,
	ListCard,
	PhotoLinkCard,
	PillCard,
	PreviewCard,
	SearchBtnCard,
	SelectCard,
} from 'molecules/cards';

export const FrameworkDetailCard = ({ framework, loading, tailwind }) => {
	// Render
	return (
		<DetailCard
			icon='cube'
			photo={framework.thumbnail}
			status={framework.status}
			title={framework.name}
			subtitle={framework.tagline}
			text={framework.description}
			footer={framework.updatedAt}
			loading={loading}
			tailwind={tailwind}
		/>
	);
};

export const FrameworkDisplayCard = ({ framework, loading, rootLink, children, tailwind }) => {
	const router = useRouter();
	const computedSubtitle =
		router.query.content === 'bounties'
			? `${framework.meta?.totalBounties} bounties`
			: `${framework.meta?.totalRepos} repos`;
	// Render
	return (
		<DisplayCard
			icon='cube'
			photo={framework.thumbnail}
			title={framework.name}
			subtitle={computedSubtitle}
			text={framework.tagline}
			href={`${rootLink}/frameworks/${framework.uuid}`}
			loading={loading}
			tailwind={tailwind}>
			{children}
		</DisplayCard>
	);
};

export const FrameworkListCard = ({ framework, loading, rootLink, tailwind }) => {
	// Render
	return (
		<ListCard
			icon='cube'
			photo={framework.thumbnail}
			title={framework.name}
			subtitle={framework.tagline}
			href={`${rootLink}/frameworks/${framework.uuid}`}
			loading={loading}
			tailwind={tailwind}>
			<FrameworkCardActions type='list' framework={framework} rootLink={rootLink} />
		</ListCard>
	);
};

export const FrameworkPhotoLinkCard = ({ framework, loading, rootLink, children, tailwind }) => {
	const router = useRouter();
	const computedSubtitle =
		router.query.content === 'bounties'
			? `${framework.meta?.totalBounties} bounties`
			: `${framework.meta?.totalRepos} repos`;
	// Render
	return (
		<PhotoLinkCard
			icon='cube'
			photo={framework.thumbnail}
			title={framework.name}
			subtitle={computedSubtitle}
			href={`${rootLink}/frameworks/${framework.uuid}`}
			loading={loading}
			tailwind={tailwind}>
			{children}
		</PhotoLinkCard>
	);
};

export const FrameworkPillCard = ({ framework, loading, rootLink, children, tailwind }) => {
	const router = useRouter();
	const computedSubtitle =
		router.query.content === 'bounties'
			? `${framework.meta?.totalBounties} bounties`
			: `${framework.meta?.totalRepos} repos`;

	// Render
	return (
		<PillCard
			icon='cube'
			photo={framework.thumbnail}
			title={framework.name}
			subtitle={computedSubtitle}
			href={`${rootLink}/frameworks/${framework.uuid}`}
			loading={loading}
			tailwind={tailwind}>
			{children}
		</PillCard>
	);
};

export const FrameworkPreviewCard = ({ framework, loading, rootLink, tailwind }) => {
	// Render
	return (
		<PreviewCard
			icon='cube'
			photo={framework.thumbnail}
			title={framework.name}
			subtitle={framework.tagline}
			loading={loading}
			href={rootLink + `/frameworks/${framework.uuid}`}
			tailwind={tailwind}>
			<BorderLink size='icon' icon='chevron-right' href={`${rootLink}/frameworks/${framework.uuid}`} />
		</PreviewCard>
	);
};

export const FrameworkSearchCard = ({ framework, onClick, tailwind }) => {
	// Render
	return (
		<SearchBtnCard
			icon='cube'
			modelName='framework'
			photo={framework.thumbnail}
			title={framework.name}
			text={framework.tagline}
			onClick={() => onClick(framework)}
			tailwind={tailwind}
		/>
	);
};

export const FrameworkSelectCard = ({ framework, selected = [], onClick, loading, tailwind }) => {
	// Render
	return (
		<SelectCard
			icon='cube'
			photo={framework.thumbnail}
			title={framework.name}
			loading={loading}
			selected={selected.map(v => v.id).includes(framework.id)}
			onClick={() => onClick(framework)}
			tailwind={tailwind}
		/>
	);
};
